import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { api } from '../api/axios';

const Unsubscribe = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState('loading');
  const { token } = useParams();

  useEffect(() => {
    api
      .get(`/user/unsubscribe/${token}`)
      .then((response) => {
        setStatus('ready');
      })
      .catch(() => setStatus('error'));
  }, [token]);

  const handleUnsubscribe = () => {
    api
      .post(`/user/unsubscribe/${token}`)
      .then(() => setStatus('success'))
      .catch(() => setStatus('error'));
  };

  if (status === 'error') {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <div className="max-w-md p-6 bg-ivory-50 rounded-lg shadow-md">
          <h1 className="text-2xl font-bold mb-4">An error occurred</h1>
          <p className="mb-4">Please contact us at hello@buildpad.io.</p>
        </div>
      </div>
    );
  }

  if (status === 'success') {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <div className="max-w-md p-6 bg-ivory-50 rounded-lg shadow-md">
          <h1 className="text-2xl font-bold mb-4">
            You have successfully unsubscribed from marketing emails
          </h1>
          <p className="mb-4">
            If you want to subscribe again, go to your account settings and
            change your communication preferences.
          </p>
          <button
            onClick={() => navigate('/dashboard')}
            className="px-4 py-2 bg-terracotta-500 text-ivory-50 rounded hover:bg-terracotta-600 transition duration-200"
          >
            Go to dashboard
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="max-w-md p-6 bg-ivory-50 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">
          Confirm unsubscription from marketing emails
        </h1>
        <p className="mb-4">
          If you want to unsubscribe from marketing emails, click the button
          below.
        </p>
        <button
          onClick={handleUnsubscribe}
          className="px-4 py-2 bg-terracotta-500 text-ivory-50 rounded hover:bg-terracotta-600 transition duration-200"
        >
          Unsubscribe
        </button>
      </div>
    </div>
  );
};

export default Unsubscribe;
