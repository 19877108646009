import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../api/axios';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMessage('');

    try {
      const response = await api.post('/auth/forgot-password', {
        email,
      });
      if (response.status === 200) {
        setMessage(
          'If an account with that email exists, a password reset link has been sent.'
        );
        setLoading(false);
        setEmail('');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen mx-auto bg-ivory-200 text-charcoal-500 flex items-center justify-center px-4">
      <div className="max-w-md w-full lg:w-[400px] bg-ivory-50 p-10 rounded-xl shadow-lg">
        <div className="flex flex-col items-center">
          <Link to="/" className="text-4xl font-bold text-terracotta-500">
            Buildpad
          </Link>
          <h2 className="mt-6 text-3xl font-extrabold text-charcoal-600 text-center">
            Reset password
          </h2>
        </div>
        <form onSubmit={handleSubmit} className="mt-8 mb-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium mb-1">
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="mb-4 appearance-none rounded-md relative block w-full px-3 py-2 border border-ivory-400 focus:outline-none focus:ring-charcoal-200 focus:border-charcoal-200 focus:z-10 sm:text-sm"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mt-6">
            {error && <p className="mb-2 text-red-500 text-sm">{error}</p>}
            {message && <p className="mb-2 text-sm">{message}</p>}
            <button
              type="submit"
              className="disabled:opacity-50 w-full py-2.5 px-4 flex justify-center text-sm font-medium rounded-md text-ivory-50 bg-terracotta-500 hover:bg-terracotta-600 transition duration-200 ease-in-out"
              disabled={loading}
            >
              Send reset link
            </button>
          </div>
        </form>
        <div className="mt-8 text-center text-sm">
          <Link
            to="/signin"
            className="font-medium text-terracotta-500 hover:text-terracotta-600 transition duration-200 ease-in-out"
          >
            Back to sign in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
