import React from 'react';

const Features = () => {
  const featureCards = [
    {
      title: 'Brainstorm ideas',
      description: 'Find profitable ideas in your industry.',
      textColor: 'text-ivory-50',
      subTextColor: 'text-ivory-50',
      color: 'bg-terracotta-500',
      icon: '/assets/features/brainstorm.webp',
    },
    {
      title: 'Market validation',
      description:
        'Access real-world data to validate your idea and make sure you are building something that people will use and pay for. No more guessing.',
      textColor: 'text-terracotta-500',
      subTextColor: 'text-charcoal-500',
      color: 'bg-ivory-300',
      icon: '/assets/features/validation.webp',
    },
    {
      title: 'Coding help',
      description:
        'Get help throughout your development process. Build your product faster and with confidence using best practices.',
      textColor: 'text-ivory-50',
      subTextColor: 'text-ivory-50',
      color: 'bg-charcoal-500',
      icon: '/assets/features/coding.webp',
    },
    {
      title: 'Phases',
      description: 'Follow a proven process.',
      textColor: 'text-ivory-50',
      subTextColor: 'text-ivory-50',
      color: 'bg-charcoal-500',
      icon: '/assets/features/phases.webp',
    },
    {
      title: 'Launch strategy',
      description:
        'Get a detailed plan to launch your product and get guidance all the way to your first customers.',
      textColor: 'text-ivory-50',
      subTextColor: 'text-ivory-50',
      color: 'bg-terracotta-500',
      icon: '/assets/features/launch.webp',
    },
    {
      title: 'Memory',
      description:
        'The Buildpad AI learns about your project as you build it. It understands your project and will give you the help you need.',
      textColor: 'text-terracotta-500',
      subTextColor: 'text-charcoal-500',
      color: 'bg-ivory-300',
      icon: '/assets/features/memory.webp',
    },
  ];

  return (
    <section className="max-w-6xl mx-auto py-24 px-4">
      <div className="flex flex-col items-center gap-6">
        <div className="inline-block py-0.5 px-4 bg-gradient-to-r from-charcoal-400 to-charcoal-500 rounded-full shadow-lg">
          <span className="text-sm text-ivory-50 font-medium">Features</span>
        </div>
        <h2 className="max-w-xl lg:max-w-2xl mb-12 text-3xl md:text-4xl lg:text-5xl text-center font-bold">
          The complete platform for building your product
        </h2>
      </div>
      <div className="mx-auto flex flex-wrap justify-center gap-6">
        {featureCards.map((card, index) => (
          <div
            key={index}
            className={`relative w-[340px] h-[340px] ${card.color} rounded-xl overflow-hidden shadow-lg`}
          >
            {/* Content */}
            <div className="relative z-10 p-6">
              <h3 className={`text-2xl font-bold ${card.textColor} mb-2`}>
                {card.title}
              </h3>
              <p
                className={`${card.subTextColor} text-base leading-relaxed opacity-80`}
              >
                {card.description}
              </p>
            </div>

            {/* Icon */}
            <div className="absolute bottom-0 left-5">
              <img
                src={card.icon}
                alt={card.title}
                loading="lazy"
                className="w-full h-full object-contain rounded-tl-lg"
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
