import React from 'react';

const testimonials = [
  {
    name: 'Dawn Tepper',
    company: 'Founder',
    comment: "I just started to dig into Buildpad and I'm so glad that I did!",
    image: '/assets/social/dawn-tepper.webp',
  },
  {
    name: 'Francesco Cortese',
    company: 'Founder, Sheetmatcher',
    comment:
      "I want to let you know that I'm so much enjoying using Buildpad, it feels like being supported by an expert assistant/mentor.",
    image: '/assets/social/francesco-cortese.webp',
  },
  {
    name: 'Jackieline Cosares',
    company: 'Founder',
    comment:
      'The Reddit search is such a clever way to tap into real user insights.',
    image: '/assets/social/jackieline-cosares.webp',
  },
  {
    name: 'Aleksander Walkowski',
    company: 'Founder, WebLeads',
    comment:
      "I've tried Buildpad myself to help me with the validation phase - definitely can recommend.",
    image: '/assets/social/x-aleksander.webp',
  },
  {
    name: 'João Doria Neto',
    company: 'Founder',
    comment:
      "Buildpad helped 'unlock' my stall moment in product ideation and development.",
    image: '/assets/social/joao-doria-neto.webp',
  },
  {
    name: 'Roman Tikhonov',
    company: 'Founder, ReviewLab',
    comment:
      'I really like your Phases page that guides founders through the right process!',
    image: '/assets/social/x-roman.webp',
  },
  {
    name: 'Ace Apolonio',
    company: 'Founder, Audiosmith',
    comment:
      "We followed the steps on Buildpad, and now we have paying customers for an app we're passionate about.",
    image: '/assets/social/ace-apolonio.webp',
  },
  {
    name: 'Richard Uren',
    company: 'Founder',
    comment: 'Absolutely loving this tool. Genius!',
    image: '/assets/social/richard-uren.webp',
  },
  {
    name: 'Benian Tok',
    company: 'Founder, FutureSelf',
    comment: 'I really love Buildpad.',
    image: '/assets/social/benian-tok.webp',
  },
  {
    name: 'Adam Barta',
    company: 'Founder, Dev Blocks',
    link: 'https://devblocks.app',
    comment:
      'Buildpad helped me validate and refine my idea from a rough concept into something users actually want and is profitable.',
    image: '/assets/social/x-adam.webp',
  },
  {
    name: 'Irena Phaedra',
    company: 'Founder, CYcrds',
    comment: 'Your platform is giving me the feeling I am being accompanied.',
    image: '/assets/social/irena-phaedra.webp',
  },
  {
    name: 'Two Heads',
    company: 'Founder',
    comment:
      'We can call ourselves early adopters of Buildpad and we love it as indie newbies. Great job!',
    image: '/assets/social/two-heads.webp',
  },
  {
    name: 'Cliff Franco',
    company: 'Founder, Dwellingly',
    comment: 'I wanted to let you know that your app is AMAZING!!',
    image: '/assets/social/cliff-franco.webp',
  },
  {
    name: 'Leah Tucker',
    company: 'Founder',
    comment:
      'This is a godsend of a product for co-founders bringing an app to market for the first time.',
    image: '/assets/social/x-leah.webp',
  },
  {
    name: 'Pablo Junquera',
    company: 'Founder',
    comment:
      'This is next level; answers are much better, connected to Reddit, it is really well organized and structured.',
    image: '/assets/social/x-pablo.webp',
  },
];

const VerticalScrollingTestimonials = () => {
  return (
    <div className="p-8 flex flex-col animate-scrollUp">
      {[...testimonials, ...testimonials].map((testimonial, index) => (
        <TestimonialCard key={index} testimonial={testimonial} />
      ))}
    </div>
  );
};

const TestimonialCard = ({ testimonial }) => {
  return (
    <div className="bg-ivory-50 rounded-[16px] shadow-md p-6 mb-6">
      <div className="flex">
        <img
          src={testimonial.image}
          alt={testimonial.name}
          className="w-44 h-44 mr-4 rounded-full border-[1px] border-ivory-400"
        />
        <div className="gap-1 flex flex-col justify-center">
          <div className="h-8 text-terracotta-500 text-2xl">★★★★★</div>
          <p className="text-2xl font-bold leading-snug tracking-tight">
            {testimonial.comment}
          </p>
          <div className="h-8 flex items-center text-sm font-semibold">
            <p className="">{testimonial.name}</p>
            <span className="mx-1">-</span>
            <p className="text-terracotta-500">{testimonial.company}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalScrollingTestimonials;
