import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { api } from '../api/axios';
import GoogleAuthButton from '../components/GoogleAuthButton';
import VerticalScrollingTestimonials from '../components/VerticalScrollingTestimonials';

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [signInWithEmail, setSignInWithEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await api.post('/auth/sign-in', formData);
      if (response.status === 200) {
        // Redirect to dashboard or home page
        navigate('/dashboard');
      }
    } catch (error) {
      setError(
        error.response?.data?.error || 'An error occurred. Please try again.'
      );
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen w-full flex">
      <div className="w-full lg:w-2/5 p-6 flex justify-center items-center bg-ivory-50">
        <div className="w-full max-w-[400px]">
          <div className="mb-8 flex flex-col items-center">
            <Link to="/" className="text-4xl font-bold text-terracotta-500">
              Buildpad
            </Link>
            <h2 className="mt-6 text-3xl font-extrabold text-center">
              Welcome back
            </h2>
            <p className="mt-2 text-sm text-center">
              Sign in to continue your journey
            </p>
          </div>
          {signInWithEmail ? (
            <>
              <form onSubmit={handleSubmit} className="mt-6 mb-4">
                <div className="-space-y-px">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium mb-1"
                    >
                      Email address
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="mb-4 appearance-none rounded-md relative block w-full px-3 py-2 border border-ivory-400 focus:outline-none focus:ring-charcoal-200 focus:border-charcoal-200 focus:z-10 sm:text-sm"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium mb-1"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none rounded-md relative block w-full px-3 py-2 border border-ivory-400 focus:outline-none focus:ring-charcoal-200 focus:border-charcoal-200 focus:z-10 sm:text-sm"
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="mt-2">
                  <Link
                    to="/forgot-password"
                    className="text-xs text-charcoal-500 hover:text-charcoal-600 hover:underline transition duration-200 ease-in-out"
                  >
                    Forgot your password?
                  </Link>
                </div>

                <div className="mt-6">
                  {error && (
                    <p className="mb-2 text-red-500 text-sm">{error}</p>
                  )}
                  <button
                    type="submit"
                    className="disabled:opacity-50 w-full py-2.5 px-4 flex justify-center text-sm font-medium rounded-md text-ivory-50 bg-terracotta-500 hover:bg-terracotta-600 transition duration-200 ease-in-out"
                    disabled={loading}
                  >
                    Sign in
                  </button>
                </div>
              </form>
              <div className="mt-8 text-center text-sm">
                <p>
                  Sign in with Google?{' '}
                  <span
                    className="font-medium text-terracotta-500 hover:text-terracotta-600 transition duration-200 ease-in-out cursor-pointer"
                    onClick={() => setSignInWithEmail(false)}
                  >
                    Go back
                  </span>
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="space-y-4">
                <GoogleAuthButton />
                <button
                  className="disabled:opacity-50 w-full py-2.5 px-4 flex justify-center text-sm font-medium rounded-md bg-ivory-300 hover:bg-ivory-400 transition duration-200 ease-in-out"
                  onClick={() => setSignInWithEmail(true)}
                >
                  Sign in with email
                </button>
                <div className="pt-4 text-center text-sm">
                  <p>
                    Don't have an account?{' '}
                    <Link
                      to="/signup"
                      className="font-medium text-terracotta-500 hover:text-terracotta-600 transition duration-200 ease-in-out"
                    >
                      Sign up
                    </Link>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="hidden lg:block lg:w-3/5">
        <VerticalScrollingTestimonials />
      </div>
    </div>
  );
};

export default SignIn;
