// utils/attribution.js
export const captureAttribution = () => {
  // Get UTM parameters
  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get('utm_source');
  const utmMedium = urlParams.get('utm_medium');
  const utmCampaign = urlParams.get('utm_campaign');
  const ref = urlParams.get('ref');

  // Get referrer if no UTM
  const referrer = document.referrer;

  let source = 'direct';
  if (utmSource) {
    source = utmSource;
  } else if (ref) {
    source = ref;
  } else if (referrer) {
    const referrerDomain = new URL(referrer).hostname;
    source = determineSource(referrerDomain);
  }

  const attribution = {
    source: source,
    medium: utmMedium || '',
    campaign: utmCampaign || '',
    ref: ref || '',
    firstVisit: new Date().toISOString(),
    referrer: referrer,
  };

  localStorage.setItem('attribution', JSON.stringify(attribution));
};

// Helper function to determine the source based on referrer domain
const determineSource = (domain) => {
  const sources = {
    google: ['google'],
    facebook: ['facebook', 'fb'],
    instagram: ['instagram'],
    twitter: ['twitter', 't.co'],
    linkedin: ['linkedin', 'lnkd.in'],
    youtube: ['youtube', 'youtu.be'],
    pinterest: ['pinterest'],
    reddit: ['reddit'],
    tiktok: ['tiktok', 'tiktok.com'],
    bing: ['bing'],
    yahoo: ['yahoo'],
    duckduckgo: ['duckduckgo'],
    baidu: ['baidu'],
    yandex: ['yandex'],
    quora: ['quora'],
    medium: ['medium'],
    github: ['github'],
    stackoverflow: ['stackoverflow'],
    producthunt: ['producthunt'],
    'hacker news': ['news.ycombinator.com', 'ycombinator.com'],
    slack: ['slack'],
    discord: ['discord'],
    whatsapp: ['whatsapp'],
    telegram: ['telegram', 't.me'],
    email: [
      'mail',
      'outlook',
      'hotmail',
      'protonmail',
      'zoho',
      'mail.google.com',
    ],
    podcast: ['spotify', 'apple.com/podcasts', 'podcasts.google'],
    'app store': ['apps.apple.com'],
    'play store': ['play.google.com'],
  };

  for (const [source, domains] of Object.entries(sources)) {
    if (domains.some((d) => domain.includes(d))) {
      return source;
    }
  }

  return domain; // If no match, return the domain itself
};

// Get attribution data (use during signup)
export const getAttribution = () => {
  const attribution = localStorage.getItem('attribution');
  return attribution ? JSON.parse(attribution) : null;
};
