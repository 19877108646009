import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { api } from '../api/axios';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleVerification = async () => {
      try {
        const response = await api.get(`/auth/verify-email/${token}`);
        if (response.status === 200) {
          // Trigger the Plausible event for new user signup once verified
          window.plausible('user-signup');
          navigate('/dashboard');
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    handleVerification();
  }, [navigate, token]);

  return (
    <>
      {isLoading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="min-h-screen mx-auto bg-ivory-200 flex items-center justify-center px-4">
          <div className="max-w-md w-full lg:w-[400px] bg-ivory-50 p-10 rounded-xl shadow-lg">
            <div className="flex flex-col items-center">
              <Link to="/" className="text-4xl font-bold text-terracotta-500">
                Buildpad
              </Link>
              <h2 className="mt-6 text-3xl font-extrabold text-center">
                Verification failed
              </h2>
            </div>
            <div className="mt-8 text-center">
              <p className="text-lg">
                It seems like the verification link is invalid or expired.
                Please try to sign up again.
              </p>
            </div>
            <div className="mt-8 text-center">
              <Link
                to="/signup"
                className="font-medium text-terracotta-500 hover:text-terracotta-600 transition duration-200 ease-in-out"
              >
                Go to sign up
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyEmail;
