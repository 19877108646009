import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import useIsMobile from '../../hooks/useMobile';

const Objections = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const isMobile = useIsMobile();

  const sections = [
    {
      title: 'Intelligent AI following a proven process',
      description:
        'The Buildpad AI has been designed to build successful products through a proven process.',
      tag: 'Intelligence',
      buttonText: 'Placeholder button text',
      image: '/assets/objections/intelligence.webp',
    },
    {
      title: 'Phases, tools, and memory',
      description:
        'Buildpad includes everything you need to build your product.',
      tag: 'Complete package',
      buttonText: 'Placeholder button text',
      image: '/assets/objections/memory-phases-tools.webp',
    },
    {
      title: 'Buildpad meets you at your current level',
      description:
        'No matter your skill level, Buildpad meets you where you are at and guides you forward.',
      tag: 'Easy to use',
      buttonText: 'Placeholder button text',
      image: '/assets/objections/easy-to-use.webp',
    },
    {
      title: 'Onboard your existing project',
      description:
        'Bringing your existing project into Buildpad is easy with just one conversation.',
      tag: 'Simple onboarding',
      buttonText: 'Placeholder button text',
      image: '/assets/objections/onboarding.webp',
    },
    {
      title: 'Secure with privacy in mind',
      description:
        'Industry standard data encryption and privacy mode keeps your project secure and private.',
      tag: 'Security',
      buttonText: 'Placeholder button text',
      image: '/assets/objections/security.webp',
    },
  ];

  const Section = ({ data, index }) => {
    // Configure to watch for intersection with middle third of screen
    const { ref } = useInView({
      threshold: 0.6, // Adjust this value to fine-tune sensitivity
      rootMargin: '-33% 0px -33% 0px', // This creates the middle third "zone"
      onChange: (inView) => {
        if (inView) setCurrentSection(index);
      },
    });

    return (
      <div ref={ref} className="flex flex-col py-8">
        {/* Mobile image - only shown on small screens */}
        <div className="block lg:hidden mb-8">
          <div className="relative p-1.5 md:p-2 bg-ivory-50/25 rounded-2xl shadow-lg">
            <div className="border border-ivory-350 rounded-xl overflow-hidden">
              <img
                src={data.image}
                alt={data.tag}
                loading="lazy"
                className="w-full h-auto mx-auto"
              />
            </div>
          </div>
        </div>

        {/* Content section */}
        <div
          className={`transition-opacity duration-300 ${
            index === currentSection ? '' : isMobile ? '' : 'opacity-50'
          }`}
        >
          <div className="inline-block py-0.5 px-4 bg-gradient-to-r from-charcoal-400 to-charcoal-500 rounded-full shadow-lg">
            <span className="text-sm text-ivory-50 font-medium">
              {data.tag}
            </span>
          </div>
          <h2 className="text-3xl lg:text-4xl font-bold mt-4">{data.title}</h2>
          <p className="mt-4 text-lg md:text-xl text-charcoal-350">
            {data.description}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-6xl mx-auto px-4">
      <div className="flex flex-col lg:flex-row lg:gap-10">
        {/* Left column with scrolling sections */}
        <div className="w-full lg:w-1/2 space-y-8 lg:space-y-44">
          {sections.map((section, index) => (
            <Section key={index} data={section} index={index} />
          ))}
        </div>

        {/* Right column with fixed image - only shown on large screens */}
        <div className="hidden lg:block w-full lg:w-1/2">
          <div className="lg:sticky lg:top-1/2 lg:transform lg:-translate-y-1/2 mt-8 lg:mt-[124px] lg:-mb-[294px] flex justify-center lg:justify-end">
            <div className="relative p-1.5 md:p-2 bg-ivory-50/25 rounded-2xl shadow-lg">
              <div className="border border-ivory-350 rounded-xl overflow-hidden">
                <img
                  src={sections[currentSection].image}
                  alt={sections[currentSection].tag}
                  loading="lazy"
                  className="w-[500px] h-auto transition-opacity duration-300"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Objections;
