import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../api/axios';
import NavBar from '../components/LandingPage/NavBar';
import Objections from '../components/LandingPage/Objections';
import Footer from '../components/LandingPage/Footer';
import FinalCta from '../components/LandingPage/FinalCta';
import LastJoined from '../components/LandingPage/LastJoined';
import BuiltWithBuildpad from '../components/LandingPage/BuiltWithBuildpad';
import ScrollingTestimonials from '../components/LandingPage/ScrollingTestimonials';
import { captureAttribution } from '../utils/attribution';
import { PiPathBold } from 'react-icons/pi';
import GoogleAuthButtonLanding from '../components/GoogleAuthButtonLanding';
import Features from '../components/LandingPage/Features';
import FrancescoTestimonial from '../components/LandingPage/FrancescoTestimonial';
import AceTestimonial from '../components/LandingPage/AceTestimonial';

const LandingPage = () => {
  const [userCount, setUserCount] = useState(null);
  const [userCountLastWeek, setUserCountLastWeek] = useState(null);

  const videoRef = useRef(null);

  useEffect(() => {
    api.get('/general/user-count').then((res) => {
      setUserCount(res.data.user_count);
      setUserCountLastWeek(res.data.user_count_last_week);
    });

    // Preload the video
    if (videoRef.current) {
      videoRef.current.load();
      videoRef.current.addEventListener(
        'canplaythrough',
        () => {
          videoRef.current.play();
        },
        { once: true }
      );
    }

    // Capture attribution
    captureAttribution();
  }, []);

  return (
    <div className="min-h-screen w-full bg-ivory-200">
      <NavBar home={true} />
      <div className="container pt-28 lg:pt-44 pb-20 px-2 flex flex-col justify-center items-center text-charcoal-500">
        <div className="py-1 px-4 bg-gradient-to-r from-charcoal-400 to-charcoal-500 rounded-full shadow-lg">
          <span className="text-sm text-ivory-50 font-medium">
            AI-Guided Product Building
          </span>
        </div>
        <h1 className="max-w-4xl mt-8 text-center text-charcoal-600 text-5xl md:text-7xl font-bold">
          Build products that people actually want
        </h1>
        <p className="max-w-2xl mt-10 text-center text-xl md:text-2xl text-charcoal-350">
          Buildpad is a platform that helps founders go from idea to successful
          product
        </p>
        <div className="mt-10 flex flex-col justify-center items-center">
          <div className="w-full md:w-auto flex flex-col md:flex-row items-center gap-4">
            <Link
              to="/signup"
              className="w-full md:w-auto px-6 py-3 flex justify-center items-center gap-2 text-lg text-ivory-50 bg-terracotta-500 hover:bg-terracotta-600 border-[0.5px] border-terracotta-500 rounded-lg transition duration-200 ease-in-out"
            >
              <PiPathBold size={18} /> Start with 3 free phases
            </Link>
            <GoogleAuthButtonLanding />
          </div>
          <div className="mt-7">
            <LastJoined userCount={userCountLastWeek} />
          </div>
        </div>
      </div>
      <div className="container px-4 lg:px-10">
        <div className="relative p-1.5 md:p-3 bg-ivory-50/25 rounded-2xl shadow-lg">
          <div className="border border-ivory-350 rounded-xl overflow-hidden">
            <video
              ref={videoRef}
              loop
              muted
              playsInline
              poster="/assets/hero/landing-page-poster.webp"
              className="w-full scale-[100.2%]"
            >
              <source src="/assets/hero/landing-page.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className="mt-20 mb-10 lg:mt-20 lg:mb-20">
        <ScrollingTestimonials userCount={userCount} />
      </div>
      <div className="container my-10 lg:mt-44 mb-20 lg:mb-64 px-4">
        <Objections />
      </div>
      <div>
        <AceTestimonial />
      </div>
      <div id="buildpad-products" className="mt-6 lg:mt-16 mb-8 lg:mb-12">
        <BuiltWithBuildpad />
      </div>
      <div>
        <FrancescoTestimonial />
      </div>
      <div id="features" className="mt-6 lg:mt-12">
        <Features />
      </div>
      <div className="container mt-10 lg:mt-32 mb-24 lg:mb-44 px-4">
        <FinalCta />
      </div>
      <div className="container mt-24 lg:mt-28 px-4">
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
