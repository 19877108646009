import React, { useEffect, lazy, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
// import Banner from './components/Banner';
import LandingPage from './pages/LandingPage';
import NotFound from './pages/NotFound';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import VerifyEmail from './pages/VerifyEmail';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Invite from './pages/Invite';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Unsubscribe from './pages/Unsubscribe';
import Pricing from './pages/Pricing';
import LoadingScreen from './components/LoadingScreen';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));

const AppContent = () => {
  const location = useLocation();
  const publicPaths = [
    '/',
    '/pricing',
    '/signup',
    '/signin',
    '/verify-email',
    '/forgot-password',
    '/reset-password',
    '/invite',
    '/terms',
    '/privacy',
    '/progress',
    '/unsubscribe',
  ];

  if (
    publicPaths.some((path) => location.pathname === path) ||
    location.pathname.startsWith('/verify-email/') ||
    location.pathname.startsWith('/reset-password/') ||
    location.pathname.startsWith('/invite/') ||
    location.pathname.startsWith('/unsubscribe/')
  ) {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/verify-email/:token" element={<VerifyEmail />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/invite/:token" element={<Invite />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/unsubscribe/:token" element={<Unsubscribe />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  return (
    <Suspense fallback={<LoadingScreen />}>
      <AuthenticatedApp />
    </Suspense>
  );
};

const App = () => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  // Add the useEffect hook for viewport height calculation
  useEffect(() => {
    const setVhVariable = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', setVhVariable);
    setVhVariable();

    return () => {
      window.removeEventListener('resize', setVhVariable);
    };
  }, []);

  if (isLandingPage) {
    return (
      <div className="bg-ivory-200">
        {/* <Banner /> */}
        <LandingPage />
      </div>
    );
  }

  return (
    <div
      className="flex flex-col bg-ivory-200 text-charcoal-500"
      style={{ height: 'calc(var(--vh, 1vh) * 100)' }}
    >
      <div className="relative flex-grow flex overflow-hidden">
        <AppContent />
      </div>
    </div>
  );
};

export default App;
