import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, withTokenRefresh } from '../api/axios';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { getAttribution } from '../utils/attribution';

const GoogleAuthButton = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthStatus = withTokenRefresh(
      () => api.get('/auth/check-auth'),
      (response) => {
        if (response.status === 200) {
          setIsAuthenticated(true);
        }
      },
      (error) => {
        console.error('Authentication failed:', error);
        setIsAuthenticated(false);
      }
    );

    checkAuthStatus();
  }, []);

  const authenticateWithGoogle = async (userInfo) => {
    try {
      const response = await api.post('/auth/google-auth', userInfo);
      if (response.status === 200) {
        if (response.data.new_user) {
          // Trigger the Plausible event for new user signup
          window.plausible('user-signup');
        }
        setIsAuthenticated(true);
      }
    } catch (error) {
      if (error.response) {
        throw new Error(
          error.response.data.message || 'Google authentication failed!'
        );
      } else if (error.request) {
        throw new Error('Network error: Unable to authenticate with Google');
      } else {
        throw new Error('Error: ' + error.message);
      }
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const userInfo = await axios.get(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: { Authorization: `Bearer ${codeResponse.access_token}` },
          }
        );

        // Get attribution data
        const attribution = getAttribution();

        // Combine userInfo.data with attribution data
        const combinedData = {
          ...userInfo.data,
          attribution: attribution,
        };

        await authenticateWithGoogle(combinedData);
      } catch (error) {
        console.error('Error during Google authentication:', error.message);
        // Might want to set some state here to show an error message to the user
      }
    },
    onError: (error) => console.log('Google Authentication Failed:', error),
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [navigate, isAuthenticated]);

  return (
    <button
      onClick={login}
      className="gsi-material-button w-full max-w-[400px] h-10 px-3 py-2 text-sm font-medium text-gray-200 bg-[#131314] border border-[#8e918f] rounded-md shadow-sm hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-all duration-200 relative overflow-hidden group"
      style={{ maxWidth: '400px', minWidth: 'min-content' }}
    >
      <div className="absolute inset-0 transition-opacity duration-200 bg-white opacity-0 group-hover:opacity-[0.08] group-focus:opacity-[0.12] group-active:opacity-[0.12]"></div>
      <div className="flex items-center justify-center w-full h-full">
        <div className="mr-3 w-5 h-5">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            style={{ display: 'block' }}
          >
            <path
              fill="#EA4335"
              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
            ></path>
            <path
              fill="#4285F4"
              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
            ></path>
            <path
              fill="#FBBC05"
              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
            ></path>
            <path
              fill="#34A853"
              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
            ></path>
            <path fill="none" d="M0 0h48v48H0z"></path>
          </svg>
        </div>
        <span className="font-roboto font-medium">Continue with Google</span>
      </div>
    </button>
  );
};

export default GoogleAuthButton;
