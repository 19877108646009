import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-charcoal-200">
      <button
        className="w-full py-5 px-3 flex justify-between items-center text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="mr-2 text-lg font-medium text-charcoal-600">
          {question}
        </span>
        {isOpen ? (
          <FaMinus className="text-charcoal-400" />
        ) : (
          <FaPlus className="text-charcoal-400" />
        )}
      </button>
      {isOpen && <div className="pb-5 px-3 text-charcoal-500">{answer}</div>}
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question: 'What is Buildpad?',
      answer:
        'Buildpad is a platform that helps founders build products people actually want. Through AI chats structured into phases and specialized tools (like Reddit validation, user persona creation, and brainstorming), Buildpad guides you step-by-step from initial idea to launching your product and getting your first customers. Buildpad has its own memory bank, making sure our AI remembers and builds upon your previous work throughout your journey.',
    },
    {
      question: 'How much does Buildpad cost?',
      answer:
        'Buildpad is free for limited usage. For full access our Pro plan is $20/month.',
    },
    {
      question: 'Do you offer a free trial?',
      answer:
        "Yes, when you sign up you will start on the free plan. The free plan gives you access to the first three phases of building your product: 'Identify a need', 'Validate the need', and 'Solution ideation'. You also get access to the brainstorming AI, Reddit validation tool, and limited access to side chats.",
    },
    {
      question: 'How do I validate my idea with Buildpad?',
      answer:
        "Buildpad will guide you through researching the market and finding real problems to solve. One way Buildpad does that is by using keywords to scan Reddit's API, finding relevant posts and discussions. This provides real-world data that shows market needs and potential for business ideas.",
    },
    {
      question: 'Is my project data secure on Buildpad?',
      answer:
        'Yes, your data is secure. We use industry-standard encryption to protect your sensitive business data on Buildpad. If you select privacy mode your business data is never read by anyone at Buildpad.',
    },
    {
      question: 'Can I bring my existing projects into Buildpad?',
      answer:
        "Absolutely. We have a smooth onboarding process for people with existing projects who want to transition to Buildpad. It's simple and designed to get you up and running quickly. We still recommend starting from the beginning and properly validating your idea so you don't build on a faulty foundation.",
    },
    {
      question: 'Can I use Buildpad for any type of business?',
      answer:
        'Yes, Buildpad will help you create any business you want, from SaaS to agencies, e-commerce, info-products and more.',
    },
  ];

  return (
    <div id="faq" className="max-w-3xl mx-auto text-charcoal-500">
      <h2 className="text-4xl font-bold text-center mb-8 text-charcoal-700">
        Frequently asked questions
      </h2>
      <div className="space-y-2">
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
