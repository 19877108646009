import React, { useEffect, useState } from 'react';

const CompanyCard = ({ company, onMouseEnter, onMouseLeave }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = `/assets/built-with-buildpad/${company.path}`;

    img.onload = () => setImageLoaded(true);

    return () => {
      img.onload = null;
    };
  }, [company.path]);

  return (
    <div
      className="relative group flex-shrink-0"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {imageLoaded ? (
        <img
          src={`/assets/built-with-buildpad/${company.path}`}
          alt={`${company.name}`}
          className="h-32 lg:h-64 w-[228px] lg:w-[455px] border-[0.5px] border-ivory-350 rounded-lg"
        />
      ) : (
        <div className="h-32 lg:h-64 w-[228px] lg:w-[455px] bg-ivory-300 animate-pulse border-[0.5px] border-ivory-350 rounded-lg" />
      )}

      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-all duration-300">
        <div className="absolute inset-0 bg-black/25 rounded-lg"></div>
        <a
          href={company.link}
          target="_blank"
          rel="noreferrer dofollow"
          className="invisible group-hover:visible text-ivory-50 bg-charcoal-500 px-4 py-2 rounded-md shadow-lg hover:bg-charcoal-700 z-10 translate-y-3 group-hover:translate-y-0 transition-all duration-300"
        >
          Visit {company.name}
        </a>
      </div>
    </div>
  );
};

const TrustedBy = () => {
  const [isTopHovered, setIsTopHovered] = useState(false);
  const [isBottomHovered, setIsBottomHovered] = useState(false);
  const [displayText, setDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);

  const companiesTop = [
    {
      name: 'Audiosmith',
      link: 'https://audiosmith.me?ref=Buildpad',
      path: 'audiosmith.webp',
    },
    {
      name: 'Breathific',
      link: 'https://www.renuzoral.com/pages/breathific?ref=Buildpad',
      path: 'breathific.webp',
    },
    {
      name: 'Cultural City Cards',
      link: 'https://culturalcitycards.com?ref=Buildpad',
      path: 'culturalcitycards.webp',
    },
    {
      name: 'Dev Blocks',
      link: 'https://devblocks.app?ref=Buildpad',
      path: 'devblocks.webp',
    },
    {
      name: 'Equalizer',
      link: 'https://www.eqzer.com/?ref=Buildpad',
      path: 'equalizer.webp',
    },
  ];

  const companiesBottom = [
    {
      name: 'SwiftSoft',
      link: 'https://swiftsoft.us?ref=Buildpad',
      path: 'swiftsoft.webp',
    },
    {
      name: 'ReviewLab',
      link: 'https://reviewlab.app?ref=Buildpad',
      path: 'reviewlab.webp',
    },
    {
      name: 'Sheetmatcher',
      link: 'https://sheetmatcher.com?ref=Buildpad',
      path: 'sheetmatcher.webp',
    },
    {
      name: 'Siteskite',
      link: 'https://siteskite.com?ref=Buildpad',
      path: 'siteskite.webp',
    },
    {
      name: 'Sumnews',
      link: 'https://sumnews.net?ref=Buildpad',
      path: 'sumnews.webp',
    },
    {
      name: 'VibeSplit',
      link: 'https://vibesplit.com?ref=Buildpad',
      path: 'vibesplit.webp',
    },
  ];

  useEffect(() => {
    const rotatingTexts = [
      'SaaS',
      'Agency',
      'Product',
      'Platform',
      'App',
      'Service',
    ];

    const typingDuration = 500; // Total time to type any word
    const erasingDuration = 500; // Total time to erase any word
    const wordDelay = 4000; // Time to wait between typing and erasing

    const currentWord = rotatingTexts[wordIndex];
    const typingSpeed = typingDuration / currentWord.length;
    const erasingSpeed = erasingDuration / currentWord.length;

    if (!isDeleting && displayText === currentWord) {
      // Word is complete, wait before deleting
      setTimeout(() => setIsDeleting(true), wordDelay);
      return;
    }

    if (isDeleting && displayText === '') {
      // Word is deleted, move to next word
      setIsDeleting(false);
      setWordIndex((prev) => (prev + 1) % rotatingTexts.length);
      return;
    }

    const timeout = setTimeout(
      () => {
        setDisplayText((prev) => {
          if (isDeleting) {
            return prev.slice(0, -1);
          }
          return currentWord.slice(0, prev.length + 1);
        });
      },
      isDeleting ? erasingSpeed : typingSpeed
    );

    return () => clearTimeout(timeout);
  }, [displayText, isDeleting, wordIndex]);

  return (
    <div className="mx-auto py-24">
      <div className="flex flex-col items-center gap-6">
        <div className="inline-block py-0.5 px-4 bg-gradient-to-r from-charcoal-400 to-charcoal-500 rounded-full shadow-lg">
          <span className="text-sm text-ivory-50 font-medium">
            Products built with Buildpad
          </span>
        </div>
        <h2 className="mb-12 text-4xl lg:text-5xl text-center font-bold">
          <span className="block md:inline">Build your next</span>{' '}
          <span className="relative w-[150px] lg:w-[180px] inline-block">
            <span className="absolute left-1/2 -translate-x-1/2 md:left-0 md:translate-x-0 -top-8 lg:-top-10">
              {displayText}
              <span className="font-light text-charcoal-400 animate-blink">
                |
              </span>
            </span>
          </span>
        </h2>
      </div>
      <div className="relative overflow-hidden">
        <div
          className={`flex animate-scrollLeftSlowMobile lg:animate-scrollLeftFast`}
          style={{ animationPlayState: isTopHovered ? 'paused' : 'running' }}
        >
          <div className="flex gap-2 lg:gap-5">
            {[...companiesTop, ...companiesTop, ...companiesTop].map(
              (company, index) => (
                <CompanyCard
                  key={`${company.name}-${index}`}
                  company={company}
                  onMouseEnter={() => setIsTopHovered(true)}
                  onMouseLeave={() => setIsTopHovered(false)}
                />
              )
            )}
          </div>
        </div>
        <div
          className={`mt-2 lg:mt-5 flex flex-row-reverse animate-scrollRightSlowMobile lg:animate-scrollRightFast`}
          style={{ animationPlayState: isBottomHovered ? 'paused' : 'running' }}
        >
          <div className="flex flex-row-reverse gap-2 lg:gap-5">
            {[...companiesBottom, ...companiesBottom, ...companiesBottom].map(
              (company, index) => (
                <CompanyCard
                  key={`${company.name}-${index}`}
                  company={company}
                  onMouseEnter={() => setIsBottomHovered(true)}
                  onMouseLeave={() => setIsBottomHovered(false)}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedBy;
