import React from 'react';
import useIsMobile from '../../hooks/useMobile';

const LastJoined = ({ userCount }) => {
  const isMobile = useIsMobile();

  const founders = [
    { id: 1, imgSrc: '/assets/social/x-adam.webp' },
    { id: 2, imgSrc: '/assets/social/x-gizem.webp' },
    { id: 3, imgSrc: '/assets/social/francesco-cortese.webp' },
    { id: 4, imgSrc: '/assets/social/x-roman.webp' },
    { id: 5, imgSrc: '/assets/social/x-ava.webp' },
    { id: 6, imgSrc: '/assets/social/x-nathan.webp' },
    { id: 7, imgSrc: '/assets/social/x-alex.webp' },
    { id: 8, imgSrc: '/assets/social/juliette-lyons.webp' },
    { id: 9, imgSrc: '/assets/social/chris-fernandes.webp' },
  ];

  const displayedFounders = isMobile
    ? [founders[0], ...founders.slice(-4)]
    : founders;

  return (
    <div className="p-2 flex items-center">
      <div className="mr-2 flex">
        {displayedFounders.map((founder, index) => (
          <img
            key={founder.id}
            src={founder.imgSrc}
            alt={`Founder ${founder.id}`}
            className={`${
              index !== displayedFounders.length - 1 && '-mr-2'
            } w-[48px] h-[48px] rounded-full border-[1px] border-ivory-400`}
          />
        ))}
      </div>
      <div className="-space-y-0.5 flex flex-col text-charcoal-350">
        <p className="text-charcoal-500 font-semibold">{userCount} founders</p>
        <p>joined last week</p>
      </div>
    </div>
  );
};

export default LastJoined;
