import React, { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { api } from '../api/axios';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setLoading(true);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      setLoading(false);
      return;
    }

    try {
      const response = await api.post(`/auth/reset-password/${token}`, {
        new_password: password,
      });
      if (response.status === 200) {
        setMessage('Password reset successful. Redirecting to sign in...');
        setTimeout(() => navigate('/signin'), 3000);
      }
    } catch (error) {
      setError(
        error.response?.data?.error || 'An error occurred. Please try again.'
      );
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen mx-auto bg-ivory-200 text-charcoal-500 flex items-center justify-center px-4">
      <div className="max-w-md w-full lg:w-[400px] bg-ivory-50 p-10 rounded-xl shadow-lg">
        <div className="flex flex-col items-center">
          <Link to="/" className="text-4xl font-bold text-terracotta-500">
            Buildpad
          </Link>
          <h2 className="mt-6 text-3xl font-extrabold text-charcoal-600 text-center">
            Reset password
          </h2>
        </div>
        <form onSubmit={handleSubmit} className="mt-8 mb-4">
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium mb-1"
            >
              New password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              required
              className="mb-4 appearance-none rounded-md relative block w-full px-3 py-2 border border-ivory-400 focus:outline-none focus:ring-charcoal-200 focus:border-charcoal-200 focus:z-10 sm:text-sm"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium mb-1"
            >
              Confirm new password
            </label>
            <input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              required
              className="mb-4 appearance-none rounded-md relative block w-full px-3 py-2 border border-ivory-400 focus:outline-none focus:ring-charcoal-200 focus:border-charcoal-200 focus:z-10 sm:text-sm"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          <div className="mt-6">
            {error && <p className="mb-2 text-red-500 text-sm">{error}</p>}
            {message && <p className="mb-2 text-sm">{message}</p>}
            <button
              type="submit"
              className="disabled:opacity-50 w-full py-2.5 px-4 flex justify-center text-sm font-medium rounded-md text-ivory-50 bg-terracotta-500 hover:bg-terracotta-600 transition duration-200 ease-in-out"
              disabled={loading}
            >
              Reset password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
