import React from 'react';

const FrancescoTestimonial = () => {
  return (
    <div className="px-4 text-center">
      <h2 className="max-w-3xl text-3xl lg:text-4xl font-bold mx-auto mb-8">
        "I want to let you know that{' '}
        <span className="text-terracotta-500">I'm so much enjoying</span> using
        Buildpad"
      </h2>

      <div className="flex flex-col items-center">
        <img
          src="/assets/social/francesco-cortese.webp"
          alt="Profile"
          className="w-20 h-20 mb-4 border border-ivory-400 rounded-full"
        />
        <h3 className="text-xl font-semibold">Francesco Cortese</h3>
        <p className="text-lg text-charcoal-350">Founder, Sheetmatcher</p>
      </div>
    </div>
  );
};

export default FrancescoTestimonial;
