import React from 'react';
import { Link } from 'react-router-dom';
import { PiPathBold } from 'react-icons/pi';

const FinalCta = () => {
  return (
    <div className="max-w-[700px] mx-auto text-center text-charcoal-500">
      <h2 className="text-4xl md:text-5xl font-bold">
        Build products that people actually want
      </h2>
      <div className="mt-12">
        <Link
          to="/signup"
          className="w-full md:w-auto px-6 py-3 inline-flex justify-center items-center gap-2 text-lg text-ivory-50 bg-terracotta-500 hover:bg-terracotta-600 border-[0.5px] border-terracotta-500 rounded-lg transition duration-200 ease-in-out"
        >
          <PiPathBold size={18} /> Start with 3 free phases
        </Link>
      </div>
    </div>
  );
};

export default FinalCta;
