import React from 'react';

const ScrollingTestimonials = ({ userCount }) => {
  const testimonialsTop = [
    {
      name: 'Shlok Ranjan',
      company: 'Founder',
      comment:
        "Amazing product guys. I have started using it to create my own product, and it's really helping me a lot. It's just like a cofounder for my startup.",
      highlight: [
        'Amazing product guys.',
        "It's just like a cofounder for my startup.",
      ],
      image: '/assets/social/shlok-ranjan.webp',
    },
    {
      name: 'Dawn Tepper',
      company: 'Founder',
      comment:
        "I just started to dig into Buildpad and I'm so glad that I did! Having actual validation for a project I have already started (and researched) and finding out more about potential steps has been eye-opening!",
      highlight: ['Having actual validation', 'has been eye-opening!'],
      image: '/assets/social/dawn-tepper.webp',
    },
    {
      name: 'Francesco Cortese',
      company: 'Founder, Sheetmatcher',
      comment:
        "I want to let you know that I'm so much enjoying using Buildpad, it feels like being supported by an expert assistant/mentor.",
      highlight: [
        'it feels like being supported by an expert assistant/mentor.',
      ],
      image: '/assets/social/francesco-cortese.webp',
    },
    {
      name: 'Jackieline Cosares',
      company: 'Founder',
      comment:
        'The Reddit search is such a clever way to tap into real user insights.',
      highlight: ['tap into real user insights.'],
      image: '/assets/social/jackieline-cosares.webp',
    },
    {
      name: 'Aleksander Walkowski',
      company: 'Founder, WebLeads',
      comment:
        "I've tried Buildpad myself to help me with the validation phase - definitely can recommend. Especially that Reddit search feature was super helpful.",
      highlight: ['definitely can recommend.'],
      image: '/assets/social/x-aleksander.webp',
    },
    {
      name: 'João Doria Neto',
      company: 'Founder',
      comment:
        "Buildpad helped 'unlock' my stall moment in product ideation and development = so flying through the phases in one go was not only a breeze, but very enjoyable!",
      highlight: ["Buildpad helped 'unlock' my stall moment"],
      image: '/assets/social/joao-doria-neto.webp',
    },
    {
      name: 'Roman Tikhonov',
      company: 'Founder, ReviewLab',
      comment:
        'I really like your Phases page that guides founders through the right process! This kind of guidance could really help founders avoid common mistakes and increase their chances of success!',
      highlight: [
        'avoid common mistakes and increase their chances of success!',
      ],
      image: '/assets/social/x-roman.webp',
    },
    {
      name: 'Richard Uren',
      company: 'Founder',
      comment: 'Absolutely loving this tool. Genius!',
      highlight: ['Absolutely loving this tool. Genius!'],
      image: '/assets/social/richard-uren.webp',
    },
  ];

  const testimonialsBottom = [
    {
      name: 'Two Heads',
      company: 'Founder',
      comment:
        'We can call ourselves early adopters of Buildpad and we love it as indie newbies. Great job!',
      highlight: ['we love it as indie newbies.'],
      image: '/assets/social/two-heads.webp',
    },
    {
      name: 'Benian Tok',
      company: 'Founder, FutureSelf',
      comment:
        "I really love Buildpad, I'm so amazed how well you got the AI to respond and stick to each of the phases and simultaneously react to what the users says.",
      highlight: ['I really love Buildpad,'],
      image: '/assets/social/benian-tok.webp',
    },
    {
      name: 'Adam Barta',
      company: 'Founder, Dev Blocks',
      comment:
        "Buildpad helped me validate and refine my idea from a rough concept into something users actually want and is profitable. Since then I recommend this tool to any indie hacker that's getting started in the space.",
      highlight: [
        "I recommend this tool to any indie hacker that's getting started in the space.",
      ],
      image: '/assets/social/x-adam.webp',
    },
    {
      name: 'Irena Phaedra',
      company: 'Founder, CYcrds',
      comment:
        'Until now everything I have undertaken as an entrepreneur, I have done alone. Yet your platform is giving me the feeling I am being accompanied, sounds maybe weird but even creative minds need to feel backed up and encouraged.',
      highlight: [
        'Yet your platform is giving me the feeling I am being accompanied,',
      ],
      image: '/assets/social/irena-phaedra.webp',
    },
    {
      name: 'Ace Apolonio',
      company: 'Founder, Audiosmith',
      comment:
        "It was a huge help for us at Audiosmith because Buildpad's app uncovered insights we wouldn't have discovered on our own. We followed the steps on Buildpad, and now we have paying customers for an app we're passionate about.",
      highlight: [
        "We followed the steps on Buildpad, and now we have paying customers for an app we're passionate about.",
      ],
      image: '/assets/social/ace-apolonio.webp',
    },
    {
      name: 'Cliff Franco',
      company: 'Founder, Dwellingly',
      comment:
        "I wanted to let you know that your app is AMAZING!! The AI is incredibly insightful, particularly with open-ended questions. It's clear that a lot of thought has gone into its development. The UX is very impressive; super intuitive and engaging. Overall, such a great idea and well done!",
      highlight: ['your app is AMAZING!!', 'super intuitive and engaging.'],
      image: '/assets/social/cliff-franco.webp',
    },
    {
      name: 'Rachid',
      company: 'Founder',
      comment:
        "I have been in private beta for this tool and it was great. I can't wait to start using the new features you guys shipped recently.",
      highlight: [
        'I have been in private beta for this tool and it was great.',
      ],
      image: '/assets/social/rachid.webp',
    },
    {
      name: 'Leah Tucker',
      company: 'Founder',
      comment:
        'First, I love it! You guys are saints for bringing Buildpad to market. This is a godsend of a product for co-founders bringing an app to market for the first time.',
      highlight: ['godsend of a product for co-founders'],
      image: '/assets/social/x-leah.webp',
    },
    {
      name: 'Pablo Junquera',
      company: 'Founder',
      comment:
        'I used to use ChatGPT to build, research and inspire... But this is next level; answers are much better, connected to Reddit, it is really well organized and structured, it is guided, it leads the conversation, not me, it auto-saves key info',
      highlight: ['this is next level'],
      image: '/assets/social/x-pablo.webp',
    },
  ];

  return (
    <div className="w-full overflow-hidden py-8">
      <h2 className="max-w-md lg:max-w-xl mb-8 mx-auto px-2 text-center text-4xl lg:text-5xl font-bold">
        Join {userCount}+ founders building better products
      </h2>
      <div className="relative">
        <div className="absolute top-0 left-0 h-full w-8 lg:w-16 bg-gradient-to-r from-ivory-200 to-transparent z-10"></div>
        <div className="absolute top-0 right-0 h-full w-8 lg:w-16 bg-gradient-to-l from-ivory-200 to-transparent z-10"></div>
        <div className="flex my-5 animate-scrollLeftSlowMobile lg:animate-scrollLeft">
          <div className="flex">
            {[...testimonialsTop, ...testimonialsTop, ...testimonialsTop].map(
              (testimonial, index) => (
                <TestimonialCard key={index} testimonial={testimonial} />
              )
            )}
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="absolute top-0 left-0 h-full w-8 lg:w-16 bg-gradient-to-r from-ivory-200 to-transparent z-10"></div>
        <div className="absolute top-0 right-0 h-full w-8 lg:w-16 bg-gradient-to-l from-ivory-200 to-transparent z-10"></div>
        <div className="flex flex-row-reverse my-5 animate-scrollRightSlowMobile lg:animate-scrollRight">
          <div className="flex flex-row-reverse">
            {[
              ...testimonialsBottom,
              ...testimonialsBottom,
              ...testimonialsBottom,
            ].map((testimonial, index) => (
              <TestimonialCard key={index} testimonial={testimonial} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const TestimonialCard = ({ testimonial }) => {
  const highlightText = (text, highlights) => {
    if (!highlights || !Array.isArray(highlights) || highlights.length === 0)
      return text;

    const regex = new RegExp(`(${highlights.join('|')})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      highlights.some(
        (highlight) => part.toLowerCase() === highlight.toLowerCase()
      ) ? (
        <mark
          key={index}
          className="bg-terracotta-500 bg-opacity-40 text-charcoal-900 px-1 rounded"
        >
          {part}
        </mark>
      ) : (
        part
      )
    );
  };

  return (
    <div className="flex-shrink-0 w-[280px] h-[360px] mx-2 p-5 bg-ivory-50 rounded-lg shadow-md">
      <div className="mb-2 gap-4 flex items-center">
        <img
          src={testimonial.image}
          alt={testimonial.name}
          loading="lazy"
          className="w-12 h-12 rounded-full border-[1px] border-ivory-300"
        />
        <div className="flex flex-col">
          <h3 className="font-semibold">{testimonial.name}</h3>
          <p className="text-sm text-charcoal-350">{testimonial.company}</p>
        </div>
      </div>
      <div className="text-terracotta-500 text-2xl my-1">★★★★★</div>
      <p className="text-charcoal-400 leading-relaxed overflow-hidden line-clamp-[9]">
        {highlightText(testimonial.comment, testimonial.highlight)}
      </p>
    </div>
  );
};

export default ScrollingTestimonials;
