import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../api/axios';
import GoogleAuthButton from '../components/GoogleAuthButton';
import VerticalScrollingTestimonials from '../components/VerticalScrollingTestimonials';
import { getAttribution } from '../utils/attribution';

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  const [emailSignup, setEmailSignup] = useState(false);
  const [signedUp, setSignedUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const attribution = getAttribution();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Simple email validation function
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // Simple password validation function
  const validatePassword = (password) => {
    return password.length >= 8;
  };

  const validateForm = () => {
    if (!formData.firstName.trim()) {
      setError('First name is required');
      return false;
    }
    if (!formData.lastName.trim()) {
      setError('Last name is required');
      return false;
    }
    if (!formData.email.trim()) {
      setError('Email is required');
      return false;
    }
    if (!validateEmail(formData.email)) {
      setError('Invalid email format');
      return false;
    }
    if (!formData.password) {
      setError('Password is required');
      return false;
    }
    if (!validatePassword(formData.password)) {
      setError('Password must be at least 8 characters long');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    // Validate the form data
    if (!validateForm()) {
      return;
    }

    try {
      const response = await api.post('/auth/sign-up', {
        given_name: formData.firstName,
        family_name: formData.lastName,
        email: formData.email,
        password: formData.password,
        attribution,
      });
      if (response.status === 200) {
        setSignedUp(true);
        setLoading(false);
      }
    } catch (error) {
      setError(
        error.response?.data?.error || 'An error occurred. Please try again.'
      );
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen w-full flex">
      <div className="w-full lg:w-2/5 p-6 flex justify-center items-center bg-ivory-50">
        <div className="w-full max-w-[400px]">
          <div className="mb-8 flex flex-col items-center">
            <Link to="/" className="text-4xl font-bold text-terracotta-500">
              Buildpad
            </Link>
            <h2 className="mt-6 text-3xl font-extrabold text-center">
              {signedUp ? 'Check your inbox' : 'Create your account'}
            </h2>
            {!signedUp && (
              <p className="mt-2 text-sm text-center">
                to continue to Buildpad
              </p>
            )}
          </div>
          {emailSignup ? (
            signedUp ? (
              <div className="mt-8 text-center">
                <p className="text-lg">
                  We've sent a verification email to your inbox. Please check
                  your email and click the verification link to complete your
                  registration.
                </p>
              </div>
            ) : (
              <>
                <form onSubmit={handleSubmit} className="mt-8 mb-4">
                  <div className="-space-y-px">
                    <div className="flex gap-4 mb-4">
                      <div className="w-full">
                        <label
                          htmlFor="firstName"
                          className="block text-sm font-medium mb-1"
                        >
                          First name
                        </label>
                        <input
                          id="firstName"
                          name="firstName"
                          type="text"
                          required
                          className="appearance-none rounded-md relative block w-full px-3 py-2 border border-ivory-400 focus:outline-none focus:ring-charcoal-200 focus:border-charcoal-200 focus:z-10 sm:text-sm"
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="lastName"
                          className="block text-sm font-medium mb-1"
                        >
                          Last name
                        </label>
                        <input
                          id="lastName"
                          name="lastName"
                          type="text"
                          required
                          className="appearance-none rounded-md relative block w-full px-3 py-2 border border-ivory-400 focus:outline-none focus:ring-charcoal-200 focus:border-charcoal-200 focus:z-10 sm:text-sm"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium mb-1"
                      >
                        Email address
                      </label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="mb-4 appearance-none rounded-md relative block w-full px-3 py-2 border border-ivory-400 focus:outline-none focus:ring-charcoal-200 focus:border-charcoal-200 focus:z-10 sm:text-sm"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium mb-1"
                      >
                        Password
                      </label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        required
                        className="appearance-none rounded-md relative block w-full px-3 py-2 border border-ivory-400 focus:outline-none focus:ring-charcoal-200 focus:border-charcoal-200 focus:z-10 sm:text-sm"
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="mt-6">
                    {error && (
                      <p className="mb-2 text-red-500 text-sm">{error}</p>
                    )}
                    <button
                      type="submit"
                      className="disabled:opacity-50 w-full py-2.5 px-4 flex justify-center text-sm font-medium rounded-md text-ivory-50 bg-terracotta-500 hover:bg-terracotta-600 transition duration-200 ease-in-out"
                      disabled={loading}
                    >
                      Continue
                    </button>
                  </div>
                </form>
                <div className="mt-8 text-center text-sm">
                  <p>
                    Sign up with Google?{' '}
                    <span
                      className="font-medium text-terracotta-500 hover:text-terracotta-600 transition duration-200 ease-in-out cursor-pointer"
                      onClick={() => setEmailSignup(false)}
                    >
                      Go back
                    </span>
                  </p>
                </div>
              </>
            )
          ) : (
            <>
              <div className="space-y-4">
                <GoogleAuthButton />
                <button
                  className="disabled:opacity-50 w-full py-2.5 px-4 flex justify-center text-sm font-medium rounded-md bg-ivory-300 hover:bg-ivory-400 transition duration-200 ease-in-out"
                  onClick={() => setEmailSignup(true)}
                >
                  Sign up with email
                </button>
                <div className="pt-4 text-center text-sm">
                  <p>
                    Already have an account?{' '}
                    <Link
                      to="/signin"
                      className="font-medium text-terracotta-500 hover:text-terracotta-600 transition duration-200 ease-in-out"
                    >
                      Sign in
                    </Link>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="hidden lg:block lg:w-3/5">
        <VerticalScrollingTestimonials />
      </div>
    </div>
  );
};

export default SignUp;
