import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { PiPathBold } from 'react-icons/pi';
import { IoIosClose } from 'react-icons/io';

const NavBar = ({ home }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Prevent scrolling when menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    // Cleanup function
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  return (
    <nav className="fixed top-0 left-0 w-full px-4 lg:px-10 py-4 flex justify-between items-center text-charcoal-500 bg-ivory-200 z-50">
      <Link to="/">
        <h1 className="md:hidden text-2xl font-bold text-terracotta-500">
          Buildpad
        </h1>
      </Link>
      {/* Desktop Navigation */}
      <div className="hidden w-1/2 md:flex items-center gap-10">
        <Link to="/">
          <h1 className="text-2xl font-bold text-terracotta-500">Buildpad</h1>
        </Link>
        {home && (
          <>
            <HashLink
              to="#features"
              scroll={(el) =>
                el.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }
              className="hidden lg:block hover:text-terracotta-500 transition duration-200 ease-in-out"
            >
              Features
            </HashLink>
            <HashLink
              to="#buildpad-products"
              scroll={(el) =>
                el.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }
              className="hidden lg:block hover:text-terracotta-500 transition duration-200 ease-in-out"
            >
              Buildpad products
            </HashLink>
            <Link
              to="/pricing"
              className="hover:text-terracotta-500 transition duration-200 ease-in-out"
            >
              Pricing
            </Link>
          </>
        )}
      </div>
      <div className="hidden w-1/2 md:flex justify-end items-center gap-6">
        <Link
          to="/signin"
          className="hover:text-terracotta-500 transition duration-200 ease-in-out"
        >
          Sign in
        </Link>
        <Link
          to="/signup"
          className="px-6 py-2.5 flex items-center gap-2 text-ivory-50 bg-terracotta-500 hover:bg-terracotta-600 rounded-md transition duration-200 ease-in-out"
        >
          <PiPathBold size={16} /> Start for free
        </Link>
      </div>

      {/* Mobile Menu Button */}
      <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden">
        {isMenuOpen ? (
          <IoIosClose size={24} className="text-charcoal-350" />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 32 32"
            className="scale-x-[-1]"
          >
            <path d="M26 16a1 1 0 0 1-1 1H5a1 1 0 0 1 0-2h20a1 1 0 0 1 1 1ZM5 9h18a1 1 0 1 0 0-2H5a1 1 0 0 0 0 2Zm17 14H5a1 1 0 0 0 0 2h17a1 1 0 0 0 0-2Z"></path>
          </svg>
        )}
      </button>

      {/* Mobile Menu Overlay */}
      <div
        className={`md:hidden fixed inset-0 top-[60px] bg-ivory-50 border-t-[0.5px] border-ivory-350 z-40 transform transition-transform duration-300 ease-in-out ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex flex-col p-4">
          {home && (
            <>
              <h2 className="px-2 text-charcoal-400 text-sm font-semibold mt-4 mb-2">
                Main
              </h2>
              <HashLink
                to="#features"
                scroll={(el) =>
                  el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
                className="px-2 py-1 hover:bg-ivory-300 rounded transition duration-200 ease-in-out"
                onClick={() => setIsMenuOpen(false)}
              >
                Features
              </HashLink>
              <HashLink
                to="#buildpad-products"
                scroll={(el) =>
                  el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
                className="px-2 py-1 hover:bg-ivory-300 rounded transition duration-200 ease-in-out"
                onClick={() => setIsMenuOpen(false)}
              >
                Buildpad products
              </HashLink>
              <Link
                to="/pricing"
                className="px-2 py-1 hover:bg-ivory-300 rounded transition duration-200 ease-in-out"
                onClick={() => setIsMenuOpen(false)}
              >
                Pricing
              </Link>
            </>
          )}
          <h2 className="px-2 text-charcoal-400 text-sm font-semibold mt-6 mb-2">
            Account
          </h2>
          <Link
            to="/signin"
            className="px-2 py-1 hover:bg-ivory-300 rounded transition duration-200 ease-in-out"
            onClick={() => setIsMenuOpen(false)}
          >
            Sign in
          </Link>
          <Link
            to="/signup"
            className="px-6 py-3 mt-8 flex justify-center items-center gap-2 text-ivory-50 bg-terracotta-500 hover:bg-terracotta-600 rounded-md transition duration-200 ease-in-out"
            onClick={() => setIsMenuOpen(false)}
          >
            <PiPathBold size={16} /> Start with 3 free phases
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
