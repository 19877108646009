import React from 'react';

const AceTestimonial = () => {
  return (
    <div className="px-4 text-center">
      <h2 className="max-w-3xl text-3xl lg:text-4xl font-bold mx-auto mb-8">
        "We followed the steps on Buildpad, and now we have{' '}
        <span className="text-terracotta-500">paying customers</span> for an app
        we're <span className="text-terracotta-500">passionate</span> about."
      </h2>
      <div className="flex flex-col items-center">
        <img
          src="/assets/social/ace-apolonio.webp"
          alt="Profile"
          className="w-20 h-20 mb-4 border border-ivory-400 rounded-full"
        />
        <h3 className="text-xl font-semibold">Ace Apolonio</h3>
        <p className="text-lg text-charcoal-350">Founder, Audiosmith</p>
      </div>
    </div>
  );
};

export default AceTestimonial;
