import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/LandingPage/NavBar';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { PiPathBold } from 'react-icons/pi';
import Footer from '../components/LandingPage/Footer';
import FAQ from '../components/LandingPage/FAQ';
import FinalCta from '../components/LandingPage/FinalCta';

const Pricing = () => {
  const features = [
    {
      title: 'Access to the Buildpad AI',
      description: 'Get access to the most intelligent co-founder',
    },
    {
      title: 'Brainstorm problems to solve',
      description:
        'Get a list of problems to solve for your selected industries',
    },
    {
      title: 'Validate your idea',
      description: 'Understand if there is a market for your idea',
    },
    {
      title: 'Brainstorm solutions',
      description:
        'Get help coming up with creative solutions that solves your validated problem',
    },
    {
      title: 'Collaborate on projects',
      description: 'Invite your co-founders to collaborate on your project',
    },
    {
      title: 'Onboard existing projects',
      description:
        'Use Buildpad for your existing project with our easy onboarding process',
    },
    {
      title: 'Create side chats',
      description:
        'Create chats outside of the phases to get any help that you need',
    },
    {
      title: 'Upload documents and images',
      description: 'The Buildpad AI can read documents and images',
    },
    {
      title: 'Use the central context bank',
      description:
        'The Buildpad AI will learn about your project and use that context to help you',
    },
  ];

  const proFeatures = [
    'Everything in Free plan',
    '10x more usage',
    'Audience targeting phase',
    'Market validation phase',
    'Define MVP features phase',
    'Plan MVP development phase',
    'Build MVP phase',
    'Plan MVP launch phase',
    'Post-launch actions phase',
    'User persona tool',
    'Development chats',
    'Priority support',
  ];

  return (
    <div className="w-full bg-ivory-200 overflow-y-auto">
      <NavBar home={false} />
      <div className="max-w-5xl mx-auto mb-40 pt-32 px-4 flex flex-col justify-center">
        {/* Header */}
        <div className="text-center mb-16">
          <h3 className="text-terracotta-500 font-medium mb-3">Pricing</h3>
          <h1 className="text-5xl font-bold mb-6">$0/month</h1>
          <p className="text-charcoal-350 max-w-3xl mx-auto">
            Get the first 3 phases for free. Choose to upgrade later to get
            access to all phases.
          </p>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="flex items-start gap-3">
              <div className="mt-1 flex-shrink-0">
                <div className="w-5 h-5 rounded-full bg-terracotta-500 flex items-center justify-center">
                  <svg className="w-3 h-3 text-ivory-50" viewBox="0 0 12 12">
                    <path
                      fill="currentColor"
                      d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <h3 className="font-medium mb-1">{feature.title}</h3>
                <p className="text-sm text-charcoal-300">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Button */}
        <div className="mt-24 flex justify-center">
          <Link
            to="/signup"
            className="w-full md:w-auto px-6 py-3 inline-flex justify-center items-center gap-2 text-lg text-ivory-50 bg-terracotta-500 hover:bg-terracotta-600 border-[0.5px] border-terracotta-500 rounded-lg transition duration-200 ease-in-out"
          >
            <PiPathBold size={18} /> Start with 3 free phases
            {/* <PiPathBold size={18} /> Start first 3 phases free */}
          </Link>
        </div>
      </div>

      {/* Plans */}
      <div className="mx-auto px-2 text-charcoal-500">
        <h2 className="text-4xl md:text-5xl font-bold text-center mb-6">
          Want to go Pro?
        </h2>
        <p className="text-center text-charcoal-350 mb-12">
          Get 10x more usage and access to all phases with Buildpad Pro.
        </p>
        <div className="max-w-md mx-auto">
          <div className="relative flex flex-col p-6 rounded-lg border border-ivory-400">
            <h3 className="text-2xl font-bold mb-2">Pro</h3>
            <p className="text-3xl font-bold mb-4">
              $20
              <span className="text-base font-normal text-charcoal-350">
                {' '}
                / month
              </span>
            </p>
            <ul className="flex-grow mb-6">
              {proFeatures.map((feature, index) => (
                <li key={index} className="flex items-center mb-2">
                  <AiOutlineCheckCircle className="mr-2 text-charcoal-350" />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
            <Link
              to="/signup"
              className={`px-6 py-3 text-center rounded-lg text-ivory-50 bg-charcoal-500 hover:bg-charcoal-700 transition duration-200 ease-in-out`}
            >
              Get started
            </Link>
          </div>
        </div>
      </div>
      <div className="my-44 px-2">
        <FAQ />
      </div>
      <div className="container my-44 px-4">
        <FinalCta />
      </div>
      <div className="container mt-28 px-4">
        <Footer />
      </div>
    </div>
  );
};

export default Pricing;
