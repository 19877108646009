import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { api, withTokenRefresh } from '../api/axios';
import LoadingScreen from '../components/LoadingScreen';

const Invite = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    // Try to accept the invitation
    const handleInvitation = withTokenRefresh(
      () => api.get(`/project/invite/accept/${token}`),
      (response) => {
        if (response.status === 200) {
          const projectId = response.data.project.id;
          navigate(`/dashboard?invite=${projectId}`);
        }
      },
      (error) => {
        console.log('error', error);
        // Store the token in localStorage and redirect to signup
        localStorage.setItem('pendingInvitation', token);
        navigate('/dashboard');
      }
    );

    handleInvitation();
  }, [navigate, token]);

  return <LoadingScreen />;
};

export default Invite;
